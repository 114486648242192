:root {
  /* Primary colors */
  --primary-color: #1DBBD8;
  /* --primary-color: #007EFF; */
  --primary-dark: #1DBBD8;
}

.text-primary-heading {
  color: var(--primary-color);
}

.button-primary-heading {
  background-color: var(--primary-color);
  color: white;
  margin-left: 3rem;
  width: inherit;
  margin: inherit;
  font-size: medium;
}

.button-primary-heading:hover {
  color: white;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.bg-container {
  position: relative;
  background-image: url("./assets/1stSectionBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-container .center_tag {
  margin-top: 3rem;
}

.bg-container .paragraph {
  margin-top: 1.5rem;
}

.bg-container .button {
  margin-top: 3rem;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.Section_navbar {
  /* position: absolute; */
  /* top: 0; */
  width: 100%;
}

.navbar-custom .navbar-nav .nav-link,
.navbar-custom,
.navbar-custom .navbar-toggler-icon {
  color: white !important;
  font-family: 'Inter', sans-serif;
}

.Section_navbar .hamburger {
  color: white;
  font-size: x-large;
}

.navbar-brand {
  width: 10%;
}

.navbar-custom .form-control,
.navbar-custom .btn-outline-success {
  color: white;
  border-color: white;
  font-family: 'Inter', sans-serif;
}

.btn-outline-success:hover {
  background-color: transparent;
}

.navbar-custom .btn-primary {
  background-color: var(--primary-color);
  width: 12rem;
}

.button .btn-primary {
  background-color: var(--primary-color);
  width: 17rem;
  height: 3rem;
  padding: 12;
  font-weight: 600;
}

.tag {
  height: 1.8rem;
  width: 21rem;
  border: 1px solid var(--primary-color);
  border-radius: 0.3rem;
  margin-top: 3rem;
  background: #0F0B15;
  box-shadow: #9F64EF;
  box-shadow: 0px 4px 50px 0px #9F64EF66;
}

.tag p {
  color: white;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.Section_navbar .heading-size .h1 {
  color: white;
  font-size: 3rem;
  font-family: 'Inter', sans-serif;
  margin-top: 1rem;
  font-weight: bold !important;
}

.place {
  font-family: Arial, sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 2.5rem;
}

.locations {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.locations span::after {
  content: " • ";
  margin-left: 8px;
}

.locations span:last-child::after {
  content: "";
}

.blue-text {
  color: var(--primary-color);
}

.Section_navbar .paragraph p {
  width: 80%;
  max-width: 33rem;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  /* opacity: 0.8; */
  color: #c6c4c4 !important;
  font-weight: 300;
}

.slider-home {
  background-color: var(--primary-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-home p {
  font-family: 'Inter', sans-serif;
  padding: 1rem;
  margin: 0;
  max-width: 37rem;
  text-align: center;
}

.partners-container {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 112%;
  max-width: 80%;
  margin: auto;
}

.partners-text {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(180deg, #fff 0.36%, #1dbbd8 176.96%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#scroll-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#Service {
  background-color: #FFFFFF;
  width: 100%;
  justify-content: center;
}

#Service .both {
  display: flex;
  flex-direction: row;
  /* margin-top: 2rem; */
}

#Service .both .right-side {
  padding: 2rem;
}

#Service h5 {
  font-weight: bold;
  font-size: large;
}

#Service h1 {
  color: #15161D;
  font-weight: 600;
  font-size: xx-large;
  font-family: 'Inter', sans-serif;
  width: 30rem;
}

#Service .both .left-side {
  padding: 3rem;
}

#Service .both p {
  padding-top: 2rem;
}

.service-p {
  font-size: medium;
  font-style: inherit;
  font-family: 'Inter', sans-serif;
}

.service-p p {
  padding-top: 2rem;
}

.Service .container .card .card-body {
  border: none;
  border-radius: 10px;
}

#Service .card-body {
  background-color: #F4F5FA;
}

.Service .container .card-body {
  position: relative;
  background-color: white;
}

.Service .cards img {
  border-radius: 10px;
  max-width: 100%;
}

.Service .cards .card-title {
  font-weight: bold;
}

#About {
  background-color: #F5F5F5;

  width: 100%;
  justify-content: center;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:2rem; */
}

.background {
  width: 200px;
  height: 35px;
  padding: 18px 46px 17px 48px;
  border-radius: 100px;
  background: linear-gradient(555deg, #c0c0c0b3 25%, #696969e8 100%);
  box-sizing: border-box;
}

.why-choose-us {
  color: black;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

#About p {
  margin-top: 2rem;
  width: 32rem;
  color: #15161D;
  margin-bottom: 1em;
}

#About h1 {
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-top: 1rem;
}

#About h4 {
  color: #15161d;
  font-family: 'Inter', sans-serif;
  font-size: small;
  font-weight: bold;
  width: 6rem;
}

#About .about-img {
  width: 5rem;
  height: 5rem;
}

#About .both .right-side img {
  border-radius: 10px;
  max-width: 100%;
}

#blue-divider {
  background-color: var(--primary-color);
  width: 100%;

}

#blue-divider .divider-body p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: white;
  width: 21rem;
}

#blue-divider .divider-body button {
  font-family: 'Inter', sans-serif;
  width: 13rem;
  height: 3rem;
}

.bg-black {
  background-color: #15161D;
  width: 100%;
}

.bg-06060b {
  background: #06060b !important;
}

.heading {
  /* position: relative; */
  width: 100%;
  /* height: 274px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.heading .services {
  text-align: center;
  background: linear-gradient(180deg, #2e3148 38%, #15161d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 700 12rem/100% 'Inter', sans-serif;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

/* .heading .abc{
  width: 30%;
}  */
.heading .heading-1-provides-the {
  /* bottom: 0;
  position: absolute;
  margin-top: 86px; */
  width: 50%;
  color: #fff;
  font-size: xx-large;
  font-weight: bold;
  margin-top: -55px;
}

.card-body {
  background-color: #1E1E28;
}

.card-text {
  color: #7A7A83;
}

.cards .btn {
  background-color: var(--primary-color);
  margin-bottom: 2rem;
  width: 15rem;
  height: 3rem;
}

#Blogs {
  background-color: #FFFFFF;
  width: 100%;

}

#Blogs h1 {
  color: #15161D;
  font-style: bold;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
}

#Blogs h1 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 2rem;
}

#Blogs .Blog-Heading h1 {
  margin-top: 0;
}

#Blogs h2 {
  font-size: 2rem;
  color: #212529;
  font-weight: bolder;
}

#Blogs .lead {
  font-size: 1.1rem;
  color: #555555;
  margin-top: 2rem;
}

#Blogs .achievement-box {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#Blogs .achievement-box h3 {
  font-size: 2rem;
  font-weight: bold;
}

#Blogs .achievement-box p {
  color: #12141D;
  font-size: small;
}

#Blogs img {
  border-radius: 10px;
  max-width: 100%;
}

.portfolio {
  background-color: #F5F5F5;
  width: 100%;
}

.portfolio .portfolio-text h1 {
  font-family: 'Inter', sans-serif;
}

.portfolio .portfolio-description p {
  width: 50rem;
  text-align: center;
  margin-top: 1rem;
  font-family: 'Inter', sans-serif;
}

.portfolio .card-body {
  background-color: white;
  border-radius: 5px;
}

.portfolio .card-title {
  color: black;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.portfolio .card-text {
  text-align: left;
  font-size: 12px;
  margin: 0;
}

.portfolio .portfolio-card-btn .view-btn {
  background-color: #DBF2E7;
  border-radius: 2rem;
  font-size: 13px;
  width: 4rem;
  padding: 0.25rem 0.5rem;
}

.portfolio .view-more {
  display: flex;
  justify-content: center;
  align-items: center;

}

.portfolio .view-more .btn {
  width: 15rem;
  font-family: 'Inter', sans-serif;
  background-color: var(--primary-color);
  margin-bottom: 2rem;
  padding: 0;
  height: 2rem;
  font-size: small;
}

#OurTeam {
  background-color: #F5F5F5;
  width: 100%;
}

#OurTeam h2 {
  width: 19rem;
  font-weight: 700;
}

#OurTeam h3 {
  color: #000000;
  font-weight: 500;
  font-size: larger;
  text-decoration: none;
}

#OurTeam h3:hover {
  text-decoration-color: var(--primary-dark);
  text-decoration-line: underline;
  text-underline-offset: 10px;
}

#OurTeam p {
  width: 20rem;
}

#OurTeam .leader-card img {
  width: 100%;
  height: auto;
  border-radius: 10%;
}

#OurTeam .leader-card {
  text-align: center;
}

#Divider {
  background-image: url("./assets/divider.webp");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

#Divider .divider .divider-body p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  width: 45%;
  font-weight: 500;
  /* padding-bottom: 1rem; */
}

#Divider .divider .divider-body button {
  font-family: 'Inter', sans-serif;
  width: 13rem;
  height: 3rem;

}

/* Reviews CSS */
.reviews .card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reviews .card-body {
  padding: 20px;
  /* Adjusted padding for better mobile view */
  color: #555;
  font-family: 'Inter', sans-serif;
  background-color: white;
  height: 21rem;
}

.reviews .card-text {
  letter-spacing: -0.5px;
}

.reviews .img-fluid {
  max-width: 100%;
  height: auto;
}

.reviews .rounded-circle {
  width: 35px;
  height: 35px;
}

.reviews h2.display-4 {
  color: #000;
  letter-spacing: -0.38px;
}

.reviews .d-flex.align-items-center span {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.42px;
  line-height: 23px;
}

.reviews .list-unstyled {
  color: #FCAD38;
}

.reviews .btn {
  background-color: #5C5C5C;
  border: none;
}

.reviews .btnn {
  background-color: #5C5C5C;
  border: none;
}

.carousel-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.card-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.card-row::-webkit-scrollbar {
  display: none;
}

.news-Letter {
  background-image: url("./assets/news-letter.webp");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

#NewsLetter h1 {
  color: #FFFFFF;
  justify-content: center;
  font-style: bold;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
}

.news-Letter p {
  color: #FFFFFF;
  font-weight: 200;
  width: 75%;
  font-family: 'Inter', sans-serif;
  margin: auto;
}

.subscription {
  width: 65%;
}

.subscription .add-email {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
}

.subscription .submit-email {
  position: absolute;
  top: -1px;
  right: -1px;
  height: calc(100% - 0px);
  border: none;
  border-radius: 10px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background: #007EFF;
  color: #FFFFFF;
}

.subscription .input-group {
  height: 50px;
}

.footer {
  background: #15161D;
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  height: max-content;
}

.links {
  ul {
    list-style-type: none;
  }

  li a {
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.navlink-footer {
  display: block;
  /* padding: .5rem 1rem; */
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.icon-container {
  display: flex;
  gap: 8px;
  margin-top: 25px;
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  border: 2px solid #fff;
}

.icon-circle i {
  font-size: 18px;
}

.about-company {
  i {
    font-size: 15px;
  }

  a {
    color: white;

    &:hover {
      color: #4180CB
    }
  }
}

.location {
  i {
    font-size: 18px;
  }
}

.copyright p {
  border-top: 1px solid rgba(255, 255, 255, .1);
}

.footer .row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .col-12.col-md-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .right {
  display: flex;
  justify-content: center;
  gap: 15px;
  /* Adjust the spacing between terms */
}

.footer .text-white {
  color: white;
  /* Ensure text color is white */
}

.footer li {
  font-size: 14px;
  color: #FFFCF2;
  line-height: 1.9;
  font-weight: lighter;
}

#footer-last p {
  margin: 2px;
  padding: 3px;
}

.footer .container {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Footer .footer-address {
  width: inherit;
}

#NewsLetter .button-primary-headingg {
  background-color: var(--primary-color);
  color: white;
  margin-left: 3rem;
  margin: inherit;
  font-size: medium;
  background-color: var(--primary-color);
  color: white;
  margin-left: 3rem;
  margin: inherit;
  font-size: medium;
}

/* Contact us Page CSS */
#Contact-Header {
  position: relative;
  background-image: url("./assets/contact-us.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 36vh;
  width: 100%;
  z-index: 1;
}

#Contact-Header .Section_navbar {
  position: relative;
  top: 0;
  width: 100%;
}

#Contact-Header .contact-mean .icon-container {
  background-color: #1dbbd8;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 15%;
  margin-top: 0;
  font-size: 25px;
}

.icon-container a {
  color: black;
}

.contact h1 {
  font-size: 1.85rem;
  font-weight: 700;
  text-align: center;
}

.map-container {
  /* position: relative;
  top: 2px;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; */
  height: 200px;
  /* width: 314px; */
}

.map-container iframe {
  /* position: absolute;
  top: 0;
  left: 0; */
  /* width: 100%; */
  height: 100%;
}

/* Navbar Styling */
.navbar-custom .navbar-nav .nav-link,
.navbar-custom,
.navbar-custom .navbar-toggler-icon {
  color: white !important;
  font-family: 'Inter', sans-serif;
}

.navbar-brand {
  width: 10%;
}

.navbar-custom .form-control,
.navbar-custom .btn-outline-success {
  color: white;
  border-color: white;
  font-family: 'Inter', sans-serif;
  width: 7rem;
}

.nav-button {
  margin: auto;
}

#Contact-Header .text-white {
  text-align: center;
}

#Contact-Header h3 {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration-color: var(--primary-color);
  text-decoration-line: underline;
  text-underline-offset: 10px;
}

#Contact-Header p {
  color: #FFFFFF;
  /* font-size: 17px;
  margin-top: 20px; */
  font-family: 'Inter', sans-serif;
  opacity: 0.8;
  /* max-width: 600px;
  margin-left: auto;
  margin-right: auto; */
  font-weight: 300;
}

.contact {
  padding-left: 15px;
  padding-right: 15px;
}

.contact-p {
  max-width: 33rem;
}

.rounded-card-body {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  width: 100%;
}

.about-img {
  width: 30px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.message-input {
  resize: none;
}

.card-btn {
  height: 50px;
}

.btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  height: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.btn-primary:hover {
  background-color: var(--primary-color);
}

.Location {
  width: 100%;
  padding-top: 80px;
  background-color: white;
  text-align: center;
  height: 79%;
}

.Location .location-text h1 {
  font-family: 'Inter', sans-serif;
  font-size: 1.85rem;
  font-weight: 900;
}

.Location .location-text p {
  font-weight: 200;
  width: 50%;
  font-family: 'Inter', sans-serif;
  margin: auto;
}


#Question {
  background-color: #F5F5F5;
  width: 100%;
}

#Question h1 {
  font-family: 'Inter', sans-serif;
  font-size: xx-large;
}

#Question .card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#Question .card-body {
  background-color: white;
  padding: 20px;
}

#Question .icon {
  color: var(--primary-color);
  font-size: large;
}

#Question .list-group-item {
  border: none;
  padding-left: 0;
}

#Question .fa-chevron-down {
  margin-right: 0;
}

.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 39px;
}

.badge-new {
  background-color: #00bcd4;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  border: none;
  margin-right: 10px;
  width: 73px;
}

p {
  margin: 0;
}

#About-Header {
  background-image: url("./assets/contact-us.webp");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 30vh;
  height: max-content;
  width: 100%;
  z-index: 1;
}

#About-Header .Section_navbar {
  /* position: absolute;  */
  top: 0;
  width: 100%;
}

.navbar-custom .navbar-nav .nav-link,
.navbar-custom,
.navbar-custom .navbar-toggler-icon {
  color: white !important;
  font-family: 'Inter', sans-serif;
}

.navbar-brand {
  width: 10%;
}

.navbar-custom .form-control,
.navbar-custom .btn-outline-success {
  color: white;
  border-color: white;
  font-family: 'Inter', sans-serif;
  width: 7rem;
}

.navbar-custom .btn-primary {
  background-color: var(--primary-dark);
  width: auto;
  font-weight: 500;
}

.button .btn-primary {
  background-color: var(--primary-dark);
  width: 17rem;
  height: 3rem;
}

#About-Header h3 {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration-color: var(--primary-dark);
  text-decoration-line: underline;
  text-underline-offset: 10px;
}

#About-Header p {
  color: #c6c4c4 !important;
  font-size: large;
  margin-top: 21px;
  /* font-family: 'Inter', sans-serif; */
  /* opacity: 0.8; */
}

#About-Content {
  background-color: #FFFFFF;
  width: 100%;

}

.background-about {
  width: 149px;
  height: 35px;
  padding: 18px 46px 17px 48px;
  border-radius: 100px;
  background: linear-gradient(555deg, #c0c0c0b3 25%, #696969e8 100%);
  box-sizing: border-box;
}

.about-us {
  color: black;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

#About-Divider {
  background-color: var(--primary-dark);
  width: 100%;
}

#About-Divider .white {
  font-family: 'Inter', sans-serif;
  color: white;
  font-weight: 600;
}

#About-Divider .white-t {
  font-family: 'Inter', sans-serif;
  color: white;
  font-weight: 400;
  font-size: larger;
}

#About-Divider .white-p {
  font-family: 'Inter', sans-serif;
  color: white;
  font-weight: 300;
  font-size: small;
}

#About-Divider .black .no {
  font-family: 'Inter', sans-serif;
  color: black;
  font-weight: 600;
}

#About-Divider .black-p {
  font-family: 'Inter', sans-serif;
  color: black;
  font-weight: 300;
  font-size: small
}

#Our-Vision {
  background-color: #F5F5F5;
  width: 100%;
  height: auto;
}

#Our-Vision .leader-card img {
  width: 100%;
  height: auto;
  border-radius: 10%;
}

#Our-Vision .leader-card {
  text-align: center;
}

#Our-Vision h2 {
  color: #000000;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

/* #About-Content .About-Content-right {
  padding-top: 3rem;
  padding-bottom: 3rem;
} */

#About-Content .About-Content-left {
  padding: 3rem;
  padding-bottom: 3rem;
}

#About-Content h2 {
  font-weight: 600;
}

#Our-Vision p {
  color: #555555;
  font-size: medium;
  font-family: 'Inter', sans-serif;
}

#discover {
  background-image: url("./assets/about-discover.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 65vh;
  width: 100%;
}

#discover h2 {
  color: #FFFFFF;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

#discover p {
  color: #FFFFFF;
  font-size: small;
  font-family: 'Inter', sans-serif;
}

#discover .btn-primary {
  background-color: var(--primary-dark);
  width: 12rem;
}

#discover .about-videoPix {
  position: relative;
  width: 100%;
  height: 50%;
  border-radius: 10%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.play-button-img {
  width: 60px;
  height: 60px;
  filter: invert(1);
  opacity: 0.75;
}

#Our-Location {
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 22%;
}

#Our-Location h1 {
  font-size: 30px;
  font-weight: 600;
}

#Our-Location h2 {
  font-size: 20px;
  color: #212529;
}

#Our-Location .lead {
  font-size: 1.1rem;
  color: #555555;
  margin-bottom: 30px;
}

#Our-Location .achievement-box {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#Our-Location .achievement-box h3 {
  font-size: 2.5rem;
}

#Our-Location .achievement-box p {
  color: #12141D;
  font-size: small;
}

#Our-Location h1 {
  color: #000000;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

#About-Paragaph {
  background-color: var(--primary-dark);
  width: 100%;
  color: white;
}

#About-Paragaph .mission {
  color: black;
}

#About-Paragaph .divider-bodyy p {
  color: white;
  text-align: center;
  font-size: larger;
  font-family: 'Inter', sans-serif;
}

#About-Question {
  background-color: #F5F5F5;
  width: 100%;
}

#About-Question .faq-section {
  padding: 60px 0;
}

#About-Question .faq-title {
  text-align: center;
  margin-bottom: 30px;
}

#About-Question .faq-title h2 {
  font-size: 2rem;
  font-weight: bold;
}

#About-Question .faq-title p {
  color: #6c757d;
}

#About-Question .faq-content {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(0, 0, 0, .1);
  background-color: white;
  --bs-gutter-x: 0;
}

#About-Question .faq-content img {
  max-width: 100%;
  border-radius: 8px;
}

#About-Question .fa-chevron-down {
  font-size: 24px;
  color: rgba(29, 187, 216, 1);
}

#About-Question .faq-list .faq-item {
  i {
    font-size: 25px;
  }

  padding: 15px 0;
}

#About-Question .card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#About-Question .card-body {
  background-color: white;
  border-radius: 10px;
}

#About-Question .list-group-item {
  border: none;
}

#contact .contact-mean .icon-container {
  background-color: #1dbbd8;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 15%;
  margin-top: 0;
  font-size: 25px;
  padding: 15px;
}

/* Loginn Page CSS  */
#Login .container-fluid {
  min-height: 100vh;
  height: auto;
  color: #fff;
}

#Login .login-pic {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
}

#Login .login-bg {
  background-image: url(./assets/login-bg.webp);
  background-size: cover;
  position: relative;
}

.login-form {
  max-width: 414px;
  width: 100%;
}

#Login .login-form h2 {
  color: #fff;
}

#Login .login-form p {
  color: #fff;
}

.login-form .form-control {
  background: transparent;
  border: 0.5px solid #ced4da;
  color: #b8b8b8;
}

.login-form .form-control:focus {
  background: transparent;
  border-color: #94A3B8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.login-form .form-control::placeholder {
  color: #b8b8b8;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.google-logo {
  width: 22px;
  margin-right: 7px;
}

.login-form .forgot-password {
  color: #fff;
  font-size: 0.875rem;
  display: block;
  text-decoration: none;
}

.register {
  color: var(--primary-dark);
  text-decoration: none;
}

.register:hover {
  color: var(--primary-dark);
}

.login-form .forgot-password:hover {
  text-decoration: none;
}

.login-form .password-icon {
  position: absolute;
  right: 10px;
  top: 32px;
  cursor: pointer;
  color: #757a81;
}

.login-form .password-icon i {
  font-size: 1rem;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.divider hr {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 10px;
}

.divider span {
  padding: 0 10px;
  color: #fff;
}


/* Loginn Page CSS End  */

/* Signup Page CSS */

#registration {
  background-image: url(./assets/registration-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 80px;
  position: relative;
}

.registration-form {
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.registration-form .form-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333333;
}

.registration-form .form-subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.registration-form .form-group {
  margin-bottom: 20px;
}

.registration-form .form-label {
  color: white;
  margin-bottom: .5rem;
  opacity: 0.9;
}

.registration-form .form-control {
  border-radius: 7px;
  padding: 12px 16px;
  border: 1px solid #ced4da;
  background-color: transparent;
  color: #b8b8b8;
}

.registration-form .form-control:focus {
  background: transparent;
  border-color: #94A3B8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.registration-form .form-control::placeholder {
  color: #b8b8b8;
}

.registration-form .input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  color: white;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  background-color: transparent;
}

.registration-form .password-icon {
  position: absolute;
  right: 15px;
  top: 44px;
  cursor: pointer;
  color: #757a81;
}

.registration-form .password-icon i {
  font-size: 1rem;
}

.registration-form .form-submit {
  background-color: #1ebbd7;
  color: #ffffff;
  border: none;
  border-radius: 7px;
  padding: 12px 60px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 8px;
}

/* Signup Page CSS End */

.faq-drawer {
  margin-bottom: 23px;
}

.faq-drawer__content-wrapper {
  font-size: 1em;
  line-height: 1.25em;
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}

.faq-drawer__title {
  cursor: pointer;
  display: block;
  font-size: 0.95rem;
  font-weight: 700;
  padding: 23px 0 0 0;
  position: relative;
  margin-bottom: 0;
  transition: all 0.25s ease-out;
}

.faq-drawer+.faq-drawer .faq-drawer__title {
  border-top: rgba(0, 0, 0, 0.25) 1px solid;
}

.faq-drawer__title::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: " ";
  display: inline-block;
  float: right;
  height: 10px;
  left: 2px;
  position: relative;
  right: 20px;
  top: 2px;
  transform: rotate(135deg);
  transition: 0.35s ease-in-out;
  vertical-align: top;
  width: 10px;
}

.faq-drawer__content {
  padding-top: 20px;
}

.faq-drawer__content p {
  font-size: 0.95rem;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover {
  color: #4E4B52;
}

.faq-drawer__trigger:checked+.faq-drawer__title+.faq-drawer__content-wrapper {
  max-height: 350px;
}

.faq-drawer__trigger:checked+.faq-drawer__title::after {
  transform: rotate(-45deg);
  transition: 0.25s ease-in-out;
}

input[type="checkbox"] {
  display: none;
}

.google-login-container {
  width: 100%;
}

.google-login-container .google-login-button {
  width: 100%;
}

.fa-brands,
.fab {
  font-size: 20px !important;
}

/* loader css */
body{
  margin: 0;
  min-width: 100%;
  min-height: 100%;
  font-family: Arial;
}

.background-loader{
  position: fixed;
  z-index: 300;
  /* background-color: #21a1e1; */
  background-image: url("./assets/about-discover.webp");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.loader{
  position: fixed;
  z-index: 301;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  width: 200px;
  overflow: hidden;
  text-align: center;
}

.spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 303;
  border-radius: 100%;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.spinner1{
  width: 100px;
  height: 100px;
  border: 10px solid #fff;
  animation: spin 1s linear infinite;
}

.spinner2{
  width: 70px;
  height: 70px;
  border: 10px solid #fff;
  animation: negative-spin 2s linear infinite;
}

.spinner3{
  width: 40px;
  height: 40px;
  border: 10px solid #fff;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0%{
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

@keyframes negative-spin {
  0%{
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(-360deg);
  }
}

.loader-text {
  position: relative;
  top: 75%;
  color: #fff;
  font-weight: bold;
}

/* resusable classes */
.offering-services .card-body{
  height: 425px;
}
.twiter-logo {
  height: 16px;
  width: 16px;
  margin-bottom: 5px;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.border-radius-12px {
  border-radius: 12px !important;
}

.border-radius-15px {
  border-radius: 15px !important;
}
.nav-border {
  border-top: 1px solid #41484f;
  border-bottom: 1px solid #41484f;
}
.cursor-pointer {
  cursor: pointer !important;
}

.gray-border {
  border: 1px solid #959b9f !important;
}

.color-white {
  color: #F5F5F5 !important;
}

.bg-fffafa {
  background: #fffafa !important;
}

.fs-13px {
  font-size: 13px !important;
}

.fs-12px {
  font-size: 12px !important;
}


.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.fw-300 {
  font-weight: 300 !important;
}

.bg-F5F5F5 {
  background: #F5F5F5 !important;
}

.ai-inovation-section {
  background-image: url("./assets/service-sec-5-bg.webp");
}

.service-card {
  height: 425px;
}

.services-card {
  height: 475px !important;
}

.bg-card {
  background-image: url(./assets/card-bg.webp);
  background-size: cover;
}

.bg-card-data-science {
  background-image: url(./assets/bg-card-data.webp);
  background-size: cover;
  background-position: center;
}

.h-250px {
  height: 250px !important;
}

.h-175px {
  height: 175px !important;
}

.h-275px {
  height: 275px !important;
}

.h-312px {
  height: 312px !important;
}

.h-350px {
  height: 350px !important;
}

.services-card-blockchain {
  height: 450px !important;
}

.w-250px {
  width: 250px !important;
}

.w-260px {
  width: 260px !important;
}

.w-50px {
  width: 50px !important;
}

.h-50px {
  height: 50px !important;
}

.services-dataScience-card {
  height: 520px !important;
}

.contact-text {
  color: #555555;
  font-size: 14px;
}

.color-555555 {
  color: #555555 !important;
}
.color-d2d2d2{
  color: #d2d2d2 !important;
}

.color-1DBBD8{
  color: #1DBBD8 !important;
}

.left-97 {
  left: 97%;
}

.top-10px {
  top: 10px !important;
}

.footer-icon  {
  width: 25%;
}

.mt-20-percent {
  margin-top: 20% !important;
}

.mt-25-percent {
  margin-top: 25% !important;
}

.margin-n-top-300px {
  margin-top: -300px !important;
}

.w-52 {
  width: 52% !important;
}

.w-55 {
  width: 55% !important;
}

.w-65 {
  width: 65% !important;
}

.w-85 {
  width: 85% !important;
}

.w-990px {
  width: 990px !important;
}

.h-542px {
  height: 542px !important;
}

.vacancy-detail-section {
  background-image: url(./assets/about-discover.webp);
  background-size: cover;
  background-position: center;
}

.events-section {
  background-image: url(./assets/event-img-1.png);
  background-size: cover;
  background-position: center;
}

.bg-dark-blue{
  background: rgba(17, 24, 39, 0.75) !important;
}

.bg-blue-linear-gradient {
  background: linear-gradient(90deg, #1DBBD8 0%, #75D9EB 29.1%, #75D9EB 68.6%, #1DB9D8 98.1%);
}

.opacity-85 {
  opacity: 85% !important;
}

.active-nav {
  padding: 5px;
  cursor: pointer;
  font-weight: 500;
}

.active-nav.active {
  background-color: #455A6438; /* Highlighted background */
  border-bottom: 1px solid #aaa6a6; /* Underline */
}


/* Media queries for responsiveness */
@media (max-width: 1440px) {
  .place {
    margin-top: 1.5rem;
  }
} 
@media (min-width: 768px) and (max-width: 1023px) {
  .mt-25-percent {
    margin-top: 9% !important;
  }
  .mt-20-percent {
    margin-top: 5% !important;
  }
}

@media (max-width: 820px) {

  .h-542px {
    height: auto !important;
  }

  .d-block-md {
    display: block !important;
  }
  .d-none-md {
    display: none !important;
  }
  #About-Header {
    height: max-content;
    min-height: max-content;
}
#discover {
  height: max-content;
}

#Contact-Header {
  min-height: max-content;
}

.bg-container {
  min-height: max-content;
}
.text-center-md {
  text-align: center !important;
}

}


@media (max-width: 768px) {

  .w-100-md {
    width: 100% !important;
  }

  .services-dataScience-card {
    height: max-content !important;
  }
  
  .services-card {
    height: max-content !important;
  }
  .h1 {
    font-size: 2rem;
  }
/* 
  #registration {
    height: 170vh;
  } */

  .paragraph p {
    width: 90%;
    font-size: 14px;
  }

  .slider {
    height: 20%;
  }

  #Service h1 {

    width: auto;
  }

  .service-p {
    font-size: smaller;
  }

  .cards .card-body {
    height: max-content;
  }
  
  /* .service-card{
    height: max-content;
  } */

  .heading .services {
    font: 700 10rem / 100% Inter, sans-serif;
  }

  .abc {
    width: 30%;
  }

  .heading-1-provides-the {
    bottom: 45px;
    position: absolute;
    color: #fff;
    font-size: large;
    width: 33%;

  }

  .heading .services {
    font-size: 103px;
    letter-spacing: -2px;
  }

  .heading .heading-1-provides-the {
    font-size: 17px;
    margin-bottom: 76px;
    margin-top: -25px;
  }

  #blue-divider {
    height: auto
  }

  .btn-link {
    margin-bottom: 30px;
  }

  .portfolio .cards .card-body {
    height: auto;
  }

  #OurTeam h2 {
    width: auto;
  }

  #OurTeam p {
    width: auto;
  }

  #Divider .divider .divider-body p {
    width: auto;
  }

  #contact .icon-container {
    margin-bottom: 15px;
  }

  /* #About-Header {
    height: 55vh;
  } */

  .footer-icon  {
    /* width: 100%; */
  }

  /* #Contact-Header {
    height: 55vh;
  } */

  /* login page css */
  .login-form {
    max-width: 316px;
  }

  /* .text-center-md {
    text-align: center !important;
  } */

  /* login page css end */
}

@media (min-width: 320px) and (max-width: 642px) {
  .offering-services .card-body{
    height: max-content !important;
  }
  .h-250px{
    height: max-content !important;
  }  

  .service-card{
    height: max-content;
  }

  .h-175px {
    height: max-content !important;
  }

  .services-dataScience-card {
    height: max-content !important;
  }

  .footer-icon  {
    width: 100%;
  }

  .play-button-img {
    width: 30px;
    height: 30px;
  }

  /* #registration {
    height: 170vh;
  } */

  #Login .login-pic {
    display: none;
  }

  #Login .login-bg {
    height: 100vh;
  }

  .login-form {
    max-width: 290px;
  }

  .bg-container .center_tag {
    margin-top: 5px;
  }

  .Section_navbar .heading-size .h1 {
    font-size: 27px;
  }

  .bg-container .paragraph {
    margin-top: 3rem;
  }

  .bg-container .button {
    margin-top: 1rem;
  }

  .locations {
    gap: 2px;
  }

  #Blog .background {
    width: auto;
    text-align: center;
  }

  #About .background {
    width: auto;
  }

  #About h1 {
    text-align: center;
  }

  #About p {
    /* text-align: center; */
    width: auto;
  }

  #blue-divider {
    height: auto;
  }

  #blue-divider .divider-body {
    padding: 1rem;
  }

  #Blogs .lead {
    font-size: 14px;
  }

  #Blogs h1 {
    font-size: 25px;
  }

  #Blogs h2 {
    font-size: 20px;
    width: auto;
  }

  #Blogs .Blog-Heading h1 {
    font-size: 20px;
  }

  #Blogs .Blog-Heading h2 {
    font-size: 17px;
  }

  #Blogs .background {
    width: auto;
  }

  .cards .card-body {
    /* height: 25.5rem; */
    height: auto;
  }

  #Blogs .blog-content {
    text-align: center;
  }

  #Service h1 {
    font-size: 20px;
  }

  #Service .both p {
    padding-top: initial;
  }

  #Service .both {
    text-align: center;
  }

  .navbar-brand {
    width: 40% !important;
  }

  .heading .services {
    font-size: 73px;
    letter-spacing: -2px;
  }

  .heading .heading-1-provides-the {
    font-size: 17px;
    font-weight: 500;
    width: 228px;
    left: 38px;
  }

  .portfolio .portfolio-description p {
    padding: 1rem;
  }

  .heading .services {
    font: 700 5rem / 100% Inter, sans-serif;
  }

  .partners-container {
    font-size: 31px;
  }

  #Divider .divider .divider-body p {
    font-size: 15px;
  }

  #Divider .divider .divider-body {
    padding: 10px;
  }

  #Our-Vision p {
    font-size: small;
  }

  #OurTeam h2 {
    text-align: center;
  }

  #OurTeam p {
    text-align: center;
  }

  #OurTeam h3 {
    text-align: center;
  }

  #Our-Vision h2 {
    text-align: center;
  }

  #Our-Vision {
    padding: 0;
  }

  #discover h2 {
    text-align: center;
  }

  #discover p {
    text-align: center;
  }

  #discover .btn-primary {
    width: inherit;
  }

  #Our-Location h1 {
    text-align: center;
  }

  #Our-Location p {
    text-align: center;
  }

  #Our-Location h2 {
    text-align: center;
  }

  /* #Our-Location {
    margin-top: 0%;
  } */

  .subscription {
    width: inherit;
  }

  .links {
    margin-top: 15px;
  }

  .container p {
    /* font-size: 10px; */
  }

  .container .badge-new {
    display: none;
  }

  #About-Content h2 {
    text-align: center;
    font-weight: 600;
  }

  .mt-25-percent{
    margin-top: 5% !important;
  }

  /* #About-Content .About-Content-right {
    padding: 12px;
  } */

  /* #About-Content .About-Content-left {
    padding: 12px;
  } */

  #Our-Vision .About-Content-right {
    padding: 12px;
  }

  .background {
    width: 260px;
  }
/* 
  #Our-Vision .About-Content-left {
    padding: 12px;
  } */

  /* #About-Header {
    height: max-content;
    min-height: max-content;
  } */

  .top-bar {
    display: none;
  }  

  .d-none-sm {
    display: none !important;
  }

  .d-block-sm {
    display: block !important;
  }
  /* #Contact-Header {
    height: 63vh;
    height: max-content;
    min-height: max-content;
  } */
  .bg-container {
    height: max-content;
  }
  /* #About-Question .faq-section {
    padding: 35px;
  } */

  #Contact-Header .contact-title {
    margin-top: 1px;
  }

  .reviews .btn {
    display: none;
  }

  .reviews .btnn {
    display: none;
  }

  .slider-home p {
    font-size: small;
  }

  .text-center-sm {
    text-align: center !important;
  }

  .loop{
    width: 100%;
  }
  .w-100-sm {
    width: 100% !important;
  }
}

@media (min-width: 375px) and (max-width: 642px) {

  .heading .heading-1-provides-the {
    font-size: 17px;
    font-weight: 500;
    width: 228px;
    left: 68px;
  }
}

@media (min-width: 425px) and (max-width: 642px) {
  .heading .heading-1-provides-the {
    font-size: 17px;
    font-weight: 500;
    width: 228px;
    left: 97px;
  }
}

@media (max-height: 610px) {
  .mt-20-percent {
    margin-top: 28% !important;
  }
  .mt-25-percent {
    margin-top: 32% !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');